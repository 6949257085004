/* eslint-disable import/no-unused-modules */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from '../../components/common';
import SEO from '../../components/common/SEO';
import ogimage from '../../images/events/2023/ibc2023/IBC-og-image.jpg';
import { useViewport } from '../../hooks';
import DesktopEventPage from '../../components/common/DesktopEventPage';
import MobileEventPage from '../../components/common/MobileEventPage';
import banner from '../../images/events/2023/ibc2023/IBC-desktop-hero.jpg';
import mobileBanner from '../../images/events/2023/ibc2023/IBC-mobile-hero.jpg';

export default function IBCAmsterdam() {
    const { t } = useTranslation();

    const { width } = useViewport();
    const breakpoint = 500;

    return (

        <Layout title='IBC2023 - Witbe' isEvents>
            <SEO
                title='Witbe at IBC2023 - Showcasing FAST Channel Monitoring Solutions'
                ogDescription='Witbe is set to present its innovative FAST Channel Monitoring solutions at IBC2023, Amsterdam, Sep 15-18. Explore the future of media technology.'
                description='Witbe is set to present its innovative FAST Channel Monitoring solutions at IBC2023, Amsterdam, Sep 15-18. Explore the future of media technology.'
                article='article'
                image={ogimage}
            />

            {width < breakpoint ? <MobileEventPage id={4} banner={mobileBanner} /> : <DesktopEventPage id={4} banner={banner} />}
        </Layout>
    );
}
